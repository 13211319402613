<template>
    <page-placeholder v-if="project.isLoaded">

        <v-row>
            <v-col :cols="12">
                <traffic-evolution/>
            </v-col>


            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-total-conversion/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-total-sale/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-total-turnover/>
            </v-col>


            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-conversion/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-sale/>
            </v-col>
            <v-col :xl="4" :lg="4" :md="4" :cols="12">
                <traffic-quality-turnover-by-visitor/>
            </v-col>


            <v-col :cols="12">
                <traffic-quality/>
            </v-col>


            <v-col :cols="12">
                <traffic-source/>
            </v-col>


            <v-col :cols="12">
                <traffic-incoming-website/>
            </v-col>
        </v-row>
    </page-placeholder>
</template>


<script>

import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";

import TrafficQualityTotalConversion from "@/components/yooda-components/app/traffic/TrafficQualityTotalConversion";
import TrafficQualityTotalSale from "@/components/yooda-components/app/traffic/TrafficQualityTotalSale";
import TrafficEvolution from "@/components/yooda-components/app/traffic/TrafficEvolution";
import TrafficQualityTotalTurnover from "@/components/yooda-components/app/traffic/TrafficQualityTotalTurnover";
import TrafficQualityTurnoverByVisitor from "@/components/yooda-components/app/traffic/TrafficQualityTurnoverByVisitor";
import TrafficQualityConversion from "@/components/yooda-components/app/traffic/TrafficQualityConversion";
import TrafficQualitySale from "@/components/yooda-components/app/traffic/TrafficQualitySale";
import TrafficSource from "@/components/yooda-components/app/traffic/TrafficSource";
import TrafficQuality from "@/components/yooda-components/app/traffic/TrafficQuality";
import TrafficIncomingWebsite from "@/components/yooda-components/app/traffic/TrafficIncomingWebsite";

export default {
    components: {
        PagePlaceholder,
        TrafficEvolution,
        TrafficQualityTotalConversion,
        TrafficQualityTotalSale,
        TrafficQualityTotalTurnover,
        TrafficQualityTurnoverByVisitor,
        TrafficQualityConversion,
        TrafficQualitySale,
        TrafficSource,
        TrafficQuality,
        TrafficIncomingWebsite,
    },
}

</script>


